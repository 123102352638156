import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [imagesState, setImagesState] = useState([])

  useEffect(() => {
    setImagesState(images)
  }, [])

  const handlePrevClick = () => {
    const index =
      (currentImageIndex - 1 + imagesState.length) % imagesState.length
    setCurrentImageIndex(index)
  }

  const handleNextClick = () => {
    const index = (currentImageIndex + 1) % imagesState.length
    setCurrentImageIndex(index)
  }

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={imagesState[currentImageIndex]}
        alt={`Image ${currentImageIndex}`}
        style={{
          borderRadius: '6px'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '0%',
          left: '10px',
          height: '100%'
        }}
      >
        <button
          onClick={handlePrevClick}
          style={{ height: '100%', border: 'none' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="2x" color="white" />
        </button>
      </div>

      <div
        style={{
          position: 'absolute',
          top: '0%',
          right: '10px',
          height: '100%'
        }}
      >
        <button
          onClick={handleNextClick}
          style={{ height: '100%', border: 'none' }}
        >
          <FontAwesomeIcon icon={faArrowRight} size="2x" color="white" />
        </button>
      </div>
    </div>
  )
}

export default ImageCarousel
