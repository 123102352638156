import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let data = [
    {
        images: '01',
        title: 'Dignidad',
        designation: 'Como derecho de cada ser humano, siendo respetado y valorado como ser individual y social, con características y condiciones particulares, por el solo hecho de ser persona.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '02',
        title: 'Educación',
        designation: 'Como proceso de socialización de las personas, en donde se facilita el aprendizaje y la adquisición de conocimientos, habilidades, valores, creencias y hábitos a toda la comunidad.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '03',
        title: 'Legitimidad',
        designation: 'Como reconocimiento por parte de los otros.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '04',
        title: 'Transparencia',
        designation: 'Actuar coherentemente con nuestro objeto social y mostrando la verdad con nuestros hechos.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '05',
        title: 'Cultura',
        designation: 'Como eje fundamental de nuestro quehacer, entendiendo que todos pensamos, actuamos, sentimos, vivimos y soñamos diferente, para desde la individualidad construir sociedad. ',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '06',
        title: 'Resiliencia',
        designation: 'Como virtud y capacidad para adaptarse y superar dificultades en momentos adversos, con la disposición de buscar nuevos caminos y superar los obstáculos que se presentan en la vida.',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '07',
        title: 'Fatima Amar',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '09',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '11',
        title: 'John Dou',
        designation: 'Sr. Graphic Designer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },



];

export default data;