import React, { Component } from 'react'
import PageHelmet from '../component/common/Helmet'
import ModalVideo from 'react-modal-video'
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn
} from 'react-icons/fa'
import ScrollToTop from 'react-scroll-up'
import { FiChevronUp } from 'react-icons/fi'
import Header from '../component/header/Header'
import Footer from '../component/footer/Footer'
// import Carousel from './Carousel'
import ImageCarousel from './Carousel'

const SocialShare = [
  { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter />, link: 'https://twitter.com/' }
]

const imagesDespojandoImaginarios = [
  '/assets/images/portfolio/DespojandoImaginarios/2-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/3-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/4-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/5-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/6-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/7-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/8-min.jpg',
  '/assets/images/portfolio/DespojandoImaginarios/9-min.jpg'
]

const imagesLosSonidosTierra = [
  '/assets/images/portfolio/LosSonidosTierra/9-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/1-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/2-min.jpg',
  // '/assets/images/portfolio/LosSonidosTierra/3-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/4-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/5-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/6-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/7-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/8-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/10-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/11-min.jpg',
  '/assets/images/portfolio/LosSonidosTierra/12-min.jpg'
]

const imagesDialogosRegionales = [
  '/assets/images/portfolio/DialogosRegionales/1-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/2-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/3-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/4-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/5-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/6-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/7-min.jpg',
  //'/assets/images/portfolio/DialogosRegionales/8-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/9-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/10-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/11-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/12-min.jpg',
  '/assets/images/portfolio/DialogosRegionales/13-min.jpg'
]

const imagesCoSiendoSororidad = [
  '/assets/images/portfolio/CoSiendoSororidad/0-min.jpeg',
  '/assets/images/portfolio/CoSiendoSororidad/1-min.jpeg',
  '/assets/images/portfolio/CoSiendoSororidad/2-min.jpg',
  '/assets/images/portfolio/CoSiendoSororidad/3-min.jpg',
  '/assets/images/portfolio/CoSiendoSororidad/4-min.jpg',
  '/assets/images/portfolio/CoSiendoSororidad/5-min.jpg',
  '/assets/images/portfolio/CoSiendoSororidad/6-min.jpg',
]

const imagesDialogosRegionales2023 = [
  '/assets/images/portfolio/DialogosRegionales2023/1.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/2.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/3.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/4.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/5.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/6.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/7.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/8.jpg',
  '/assets/images/portfolio/DialogosRegionales2023/9.jpg',
]
class Initiatives extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false
    }
    this.openModal = this.openModal.bind(this)
    this.openModal1 = this.openModal1.bind(this)
    this.openModal2 = this.openModal2.bind(this)
    this.openModal3 = this.openModal3.bind(this)
    this.openModal4 = this.openModal4.bind(this)
  }
  openModal() {
    this.setState({ isOpen: true })
  }
  openModal1() {
    this.setState({ isOpen1: true })
  }
  openModal2() {
    this.setState({ isOpen2: true })
  }
  openModal3() {
    this.setState({ isOpen3: true })
  }
  openModal4() {
    this.setState({ isOpen4: true })
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Iniciativas" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--34"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    {' '}
                    Proyectos Realizados
                    <br />
                  </h2>
                  <p>
                    Todo lo que puede ser imaginado, es real. <br />
                    Pablo Picasso.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">

                  <div className="inner">
                    <h2 className="line-height-52">
                      Diálogos Regionales y Encuentros con el Arte 2023
                    </h2>
                    {/* <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      --
                    </p> */}
                    <p className="text-justify mt-4">
                      El proceso realizado en la Institución Educativa indígena Ubamux, en el municipio
                      de Cota, ha demostrado de manera vívida y experiencial cómo el arte, en este
                      caso la exploración del paisaje sonoro, puede ser una herramienta poderosa para
                      sensibilizar, educar y transformar la percepción de los niños y niñas sobre su
                      entorno. A través de actividades prácticas y reflexivas, los estudiantes escuchan
                      de manera atenta y consciente, reconociendo los sonidos que componen su vida
                      cotidiana y reinterpretando el mundo sonoro que les rodea.
                      <br />
                      <br />
                    </p>

                    <div className="col-md-12 mx-auto px-0">
                      <ImageCarousel images={imagesDialogosRegionales2023} />
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52">
                      CoSiendo Sororidad
                    </h2>
                    {/* <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      --
                    </p> */}
                    <p className="text-justify mt-4">
                      Las prácticas artísticas ayudan a fortalecer la identidad, el reconocimiento propio y
                      colectivo del ser como personas resilientes, donde se deconstruyen imaginarios sobre la
                      mujer, es así como se propuso desarrollar un proceso intergeneracional de formación en
                      marroquinería, como herramienta para la participación y representación de las mujeres de
                      la localidad. Se desarrollaron cuatro encuentros de tres horas diarias, en un espacio
                      artístico del barrio Siete de Agosto, y donde además del aprendizaje de este oficio, se
                      forjaron espacios de diálogo, escucha, e intercambio de saberes y experiencias,
                      generando así la sororidad entre quienes hicimos parte de este proceso.
                      <br />
                      <br />
                      El proyecto se desarrolló en un barrio popular de nuestra localidad que reúne la diversidad
                      de mujeres: trabajadoras informales, cuidadoras, mujeres que están en su hogar,
                      trabajadoras sexuales, jóvenes que habitan el barrio, estudiantes, madres cabeza de
                      hogar, entre otras, y con el cual buscamos la reivindicación de los derechos de las
                      mujeres, generar lazos y una red de apoyo, que conllevan no solo a la autonomía
                      económica, sino también un espacio de encuentro, fraternidad y empatía.
                      <br />
                      <br />
                      El proceso de formación tuvo dos componentes, uno humano y uno técnico. El
                      componente humano conlleva a un relacionamiento, un espacio de diálogo y escucha
                      entre el grupo poblacional, y donde se abordaron las siguientes temáticas en torno al
                      papel de la mujer en nuestra sociedad.
                      <br />
                      <br />
                    </p>

                    <div className="col-md-12 mx-auto px-0">
                      <ImageCarousel images={imagesCoSiendoSororidad} />
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52">
                      Diálogos Regionales y Encuentros con el Arte 2022
                    </h2>
                    {/* <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      --
                    </p> */}
                    <p className="text-justify mt-4">
                      Los paisajes sonoros de escuelas rurales de El Frailejonal
                      y El Líbano, en el municipio de La Calera, tienen
                      características distintivas que comparten. Entre estas,
                      una de las más relevantes es la que se determina por lo
                      aislados que se encuentran estos territorios, al menos en
                      el nivel urbanístico, pues se hallan inmersos en un
                      ecosistema biológico completamente interrelacionado. Se
                      escuchan fuentes sonoras típicas, casi estereotipadas,
                      dentro del imaginario colectivo de paisaje sonoro rural:
                      una gran cantidad de aves con sonoridades tónicas o
                      ruidosas que forman una masa casi ininterrumpida, la
                      presencia de fuentes hídricas, el sonido producido por el
                      viento, ya sea este directo o moviendo los árboles, etc.
                      Sin embargo, sin importar que pareciera que el estereotipo
                      se mantiene, cada una de las escuelas, y en cada uno de
                      los espacios de las mismas, las características del
                      paisaje sonoro son únicas y perceptualmente inmanente,
                      variables.
                      <br />
                      <br />
                      La injerencia humana hace que el paisaje sonoro de las dos
                      escuelas sea 'caótico', sobre todo en los momentos de
                      descanso. En estos momentos, como es de esperarse, hay
                      niños y niñas que juegan, gritan, hablan, ríen, etc. Esto
                      hace que la población estudiantil sea una de las
                      características más importantes y reconocibles de los
                      paisajes sonoros de las escuelas, al menos en los momentos
                      en que las actividades se desarrollaron.
                    </p>

                    <div className="col-md-9 mr-auto px-0 mt--30">
                      <ImageCarousel images={imagesDialogosRegionales} />
                    </div>
                    <div className="col-md-8 ml-auto m-0 px-0">
                      <div className="related-work text-center mt--30">
                        <div className="thumb">
                          <img
                            src="/assets/images/portfolio/DialogosRegionales/8-min.jpg"
                            alt="Portfolio-images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen4}
                            videoId="v0SBctIG-8c"
                            onClose={() => this.setState({ isOpen4: false })}
                          />
                          <button
                            className="video-popup position-top-center"
                            onClick={this.openModal4}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52">Los Sonidos de Mi Tierra</h2>
                    {/* <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      --
                    </p> */}
                    <p className="text-justify mt-4">
                      Desarrollamos un proceso con los niños y niñas de la
                      Escuela Rural Santa Isabel, donde a partir de intereses
                      propios, se logró consolidar una experiencia musical
                      contextualizada. Lo anterior, a partir de un proceso
                      formativo en música experimental, desde la lectura del
                      contexto en diálogo con lo rural-cotidiano, abordando de
                      manera práctica e interactiva el concepto de paisaje
                      sonoro para comprender lo que este significa y la
                      capacidad que tienen estos niños y niñas para influir en
                      sus territorios desde el aporte que hacen a su entorno
                      sonoro, que es, a la larga, un reflejo de sus condiciones
                      socioculturales y contextuales.
                      <br />
                      <br />
                      Cuando hablamos de un proceso formativo, es necesario
                      comprender que cada acción que se realiza debe tener una
                      intencionalidad pedagógica. Sin embargo, no solamente 'el
                      hacer' es considerablemente importante, pues no se puede
                      dejar de lado los sujetos que lo llevan a cabo y el
                      espacio en el que se sitúan para su desarrollo. Teniendo
                      presente esto, partimos de la reflexión que suscitó la
                      población y el espacio en la ejecución del proceso
                      artístico-formativo.
                    </p>

                    <div className="col-md-9 ml-auto px-0 mt--30">
                      <ImageCarousel images={imagesLosSonidosTierra} />
                    </div>
                    <div className="col-md-8 mr-auto m-0 px-0">
                      <div className="related-work text-center mt--30">
                        <div className="thumb">
                          <img
                            src="/assets/images/portfolio/LosSonidosTierra/3-min.jpg"
                            alt="Portfolio-images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen3}
                            videoId="IB4R01Cntiw"
                            onClose={() => this.setState({ isOpen3: false })}
                          />
                          <button
                            className="video-popup position-top-center"
                            onClick={this.openModal3}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52">
                      Despojando Imaginarios, Reconciliando Territorios
                    </h2>
                    {/* <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      --
                    </p> */}
                    <p className="text-justify mt-4">
                      Teniendo en cuenta que las comunidades indígenas han sido
                      invisibilizadas en diversos escenarios sociales,
                      económicos y culturales durante años, vemos la necesidad
                      de aportar a la reconstrucción y resignificación de estas
                      poblaciones, encontrando en la cartografía social, como
                      método de investigación, una posibilidad para visibilizar
                      su participación en la ciudadanía como agentes propios de
                      la cultura, para la reconciliación y para la construcción
                      del tejido social en nuestra ciudad. <br />
                      <br />
                      Con la intención de no ir tan lejos de la capital, sino
                      más bien, de evidenciar que incluso en contextos cercanos
                      acontece una realidad de invisibilización y no
                      reconocimiento de las comunidades ancestrales, hablaremos
                      del pueblo Muisca. Sucede que esta población es nada más y
                      nada menos que, descendiente de los habitantes
                      prehispánicos de lo que hoy es Cundinamarca, Boyacá y
                      Bogotá. Así es, Bogotá no solo ha sido una ciudad
                      receptora de comunidades originarias, sino que, es la cuna
                      de los Muiscas junto a otros municipios aledaños y su
                      vecino departamento, Boyacá.
                    </p>

                    <div className="col-md-9 mr-auto px-0 mt--30">
                      <ImageCarousel images={imagesDespojandoImaginarios} />
                    </div>
                    <div className="col-md-8 ml-auto m-0 px-0">
                      <div className="related-work text-center mt--30">
                        <div className="thumb">
                          <img
                            src="/assets/images/portfolio/DespojandoImaginarios/1-min.jpg"
                            alt="Portfolio-images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen2}
                            videoId="1LOwsHKULWc"
                            onClose={() => this.setState({ isOpen2: false })}
                          />
                          <button
                            className="video-popup position-top-center"
                            onClick={this.openModal2}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52">
                      Memoria de Sonidos Femeninos
                    </h2>
                    <p
                      className="subtitle"
                      style={{
                        fontStyle: 'italic',
                        fontSize: '1rem',
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {' '}
                      La vejez puede traducirse en imagen de valentía.
                    </p>
                    <p className="text-justify">
                      Considerando las diversas identidades, construcciones
                      sociales, estereotipos y definiciones frente al adulto
                      mayor y a la mujer, nos situamos desde un escenario
                      concreto para develar el trabajo realizado con el proyecto
                      “Memoria de Sonidos Femeninos”, el cual fue asumido desde
                      un enfoque generacional y de género porque sabemos y
                      conocemos la invisibilidad social que se le ha hecho al
                      adulto mayor y a las mujeres. <br />
                      <br />
                      El proyecto se desarrolló con una metodología
                      participativa, en donde por medio de actividades
                      artísticas se abordaron temáticas que aportaron al
                      fortalecimiento de la identidad, llegando al
                      reconocimiento propio de sujetas resilientes, empoderadas
                      y valiosas en la sociedad, y la cual se enriqueció mucho
                      más, en tanto que también hubo inmersión de los hombres.
                    </p>

                    <div className="col-12 px-0">
                      <div className="related-work text-center mt--30">
                        <div className="thumb">
                          <img
                            src="/assets/images/portfolio/memoria-sonidos-femeninos.jpg"
                            alt="Portfolio-images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen1}
                            videoId="uDWU5R15HgM"
                            onClose={() => this.setState({ isOpen1: false })}
                          />
                          <button
                            className="video-popup position-top-center"
                            onClick={this.openModal1}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52 pt-3">
                      Residencia Artística
                    </h2>
                    <p className="subtitle">
                      Mi primera residencia artística: diálogos y encuentros con
                      el arte
                    </p>
                    <p className="text-justify">
                      Iniciativa ganadora de la convocatoria “Comparte lo que
                      somos” El arte, la cultura y el patrimonio “un abrazo” de
                      esperanza Nacional – Año 2020” del Ministerio de Cultura.
                      Este proyecto consiste en la consolidación de Residencias
                      Artísticas, para estudiantes de arte en Florencia, Caquetá
                      y municipios aledaños, donde se establecen metodologías de
                      trabajo colaborativo para la implementación de prácticas
                      artísticas en un contexto propio, para visibilizar y
                      potenciar los contenidos de creación en esta zona del país
                      donde los derechos culturales son casi nulos, y así
                      posibilitar la divulgación de iniciativas culturales en
                      uno de los contextos más afectados por la violencia del
                      conflicto armado en Colombia.{' '}
                    </p>

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <h4>Proyectos ganadores de la residencia</h4>
                        {/* <div className="row">
                            <div className="col-8">
                                <span>El teatro como herramienta pedagógica en el desarrollo de la identidad cultural en Florencia Caquetá. <strong>Valentina Vásquez Álvarez</strong>  <br /> Florencia – Caquetá</span>
                            </div>
                            <div className="col-4">
                                <span>El Cielo Más que Azul. <br /> <strong> Karol Daniela Chavez Becerra </strong> <br /> San Vicente del Caguán – Caquetá</span>
                            </div>
                        </div> */}

                        <ol type="I">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <li>
                                El teatro como herramienta pedagógica en el
                                desarrollo de la identidad cultural en Florencia
                                Caquetá.{' '}
                                <strong>Valentina Vásquez Álvarez</strong>{' '}
                                <br /> Florencia – Caquetá
                              </li>
                            </div>

                            <div className="col-12 col-md-6 mt-3 mt-md-0">
                              <li>
                                El Cielo Más que Azul. <br />{' '}
                                <strong> Karol Daniela Chavez Becerra </strong>{' '}
                                <br /> San Vicente del Caguán – Caquetá
                              </li>
                            </div>
                          </div>
                        </ol>
                      </div>
                      <div className="port-view thumb mb--30 mt-3 mt-md-5">
                        <img
                          src="/assets/images/portfolio/mi-primera-residencia.jpg"
                          alt="Portfolio Images"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inner mt-5 pt-5">
                    <h2 className="line-height-52 pt-3">Diálogos Regionales</h2>
                    <p className="subtitle">
                      Diálogos regionales y encuentros con el arte.
                    </p>
                    <p className="mb-0 text-justify">
                      El proyecto fue ganador de la convocatoria del Programa
                      Nacional de Concertación Cultural 2021, del Ministerio de
                      Cultura. Este proyecto consiste en la creación de
                      escenarios de formación, investigación y reflexión
                      artística en zonas rurales del municipio de Paipa, que
                      permitan generar con las comunidades espacios de encuentro
                      en torno al arte, desde el reconocimiento del contexto y
                      las amplias posibilidades de prácticas artísticas que
                      ofrece el arte contemporáneo. En este sentido, se busca
                      crear un proceso relacional donde los artistas y la
                      comunidad a partir de interés propios, logren consolidar
                      una experiencia artística contextualizada.{' '}
                    </p>

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <div className="row mt--10">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="related-work text-center mt--30">
                              <div className="thumb">
                                <img
                                  src="/assets/images/portfolio/dialogos-regionales.jpg"
                                  alt="Portfolio-images"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="related-work text-center mt--30">
                              <div className="thumb">
                                <img
                                  src="/assets/images/portfolio/la-vaca-huevo.jpg"
                                  alt="Portfolio-images"
                                />
                                <ModalVideo
                                  channel="youtube"
                                  isOpen={this.state.isOpen}
                                  videoId="CHs75OgH-ow"
                                  onClose={() =>
                                    this.setState({ isOpen: false })
                                  }
                                />
                                <button
                                  className="video-popup position-top-center"
                                  onClick={this.openModal}
                                >
                                  <span className="play-icon"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                        <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='CHs75OgH-ow' onClose={() => this.setState({isOpen: false})} />
                        <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                    </div>

                    <div className="thumb mb--30">
                        <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                    </div>

                    <div className="thumb">
                        <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                    </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    )
  }
}
export default Initiatives
