import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 236,
                countTitle: 'Niños, niñas, jóvenes, adultos y adulto mayor.',
            },
            {
                countNum : 451,
                countTitle: 'Familias cuyos derechos culturales son limitados.',
            }
        ];

        return(
            <Fragment>
                <div className="row">
                    {/* {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))} */}
                    <div className="counterup_style--1 col-lg-6 col-md-6 col-sm-6 col-12" key={0}>
                        <h5 className="counter">
                            <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                <CountUp end={this.state.didViewCountUp ? 236 : 0} />
                            </VisibilitySensor>
                        </h5>
                        <p className="description"><strong>Directos:</strong> Niños, niñas, jóvenes, <br /> adultos y adulto mayor.</p>
                    </div>
                    <div className="counterup_style--1 col-lg-6 col-md-6 col-sm-6 col-12" key={1}>
                        <h5 className="counter">
                            <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                <CountUp end={this.state.didViewCountUp ? 451 : 0} />
                            </VisibilitySensor>
                        </h5>
                        <p className="description"><strong>Indirectos:</strong> Familias cuyos <br /> derechos culturales son limitados.</p>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;