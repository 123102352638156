import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ModalVideo from 'react-modal-video';
import { videoTagString, VideoTag } from 'react-video-tag';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Objetivos de desarollo sostenible',
        description: '¡Hagamos que las cosas pasen!',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class About extends Component{
    constructor () {
        window.scrollTo(0, 0)
        super()
        this.state = {
            isOpen: false,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false
        }
        this.openModal = this.openModal.bind(this)
        this.openModal1 = this.openModal1.bind(this)
        this.openModal2 = this.openModal2.bind(this)
        this.openModal3 = this.openModal3.bind(this)
        this.openModal4 = this.openModal4.bind(this)
        this.openModal5 = this.openModal5.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    openModal1 () {
        this.setState({isOpen1: true})
    }
    openModal2 () {
        this.setState({isOpen2: true})
    }
    openModal3 () {
        this.setState({isOpen3: true})
    }
    openModal4 () {
        this.setState({isOpen4: true})
    }
    openModal5 () {
        this.setState({isOpen5: true})
    }
    render(){
        // window.scrollTo(0, 0);
        let title = 'About',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Conócenos' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--33"  data-black-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Conoce Más Sobre Nosotros</h2>
                                    <p>¡Hagamos que las cosas pasen! <br /> . </p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">¿Quiénes somos?</h2>
                                            <p className="text-justify">
                                                Ganemos Más es una organización sociocultural, constituida por gestores culturales, 
                                                artistas, docentes y profesionales de diferentes áreas que suman sus saberes para contribuir a 
                                                la realización de proyectos sociales desde un enfoque artístico y cultural.
                                            </p>
                                            <p className="text-justify"> 
                                                Estamos convencidos que los grandes cambios surgen de ideas potentes, por ello nuestro trabajo 
                                                es hacer germinar pensamiento desde acciones que nos permitan llegar a diferentes comunidades 
                                                con apuestas creativas y solidarias.
                                            </p>
                                            <p className="text-justify">
                                                Sabemos que juntando esfuerzos los resultados pueden alcanzarse quizás en menor tiempo y con mayor 
                                                impacto, por ello, reconocemos el valor de trabajar con y para la comunidad, desde alianzas que 
                                                garanticen ampliar las oportunidades de gestión y crecimiento de los proyectos e iniciativas que emprendemos.
                                            </p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="">
                                        <img className="w-100" src="/assets/images/about/quienes-somos.jpg" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Beneficiarios</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Finding Us Area  */}
                {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">Find Your Work Now</h4>
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that.</p>
                                <a className="rn-btn btn-white" href="/about">Get Started</a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.png" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Finding Us Area  */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb d-flex justify-content-center justify-content-lg-start">
                                                    <img className="image-100-75" src="/assets/images/about/nos-inspiramos.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    {/* <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p>
                                                    <p>hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.</p> */}
                                                    <h4 className="title mb-4 mt-2">Nos inspiramos para</h4>
                                                    <ul className="liststyle">
                                                        <li>Gestionar y desarrollar convenios artísticos, culturales, sociales y medioambientales que generen impacto y contribuyan con el mejoramiento de la calidad de vida de la población beneficiaria.</li>
                                                        <li>Orientar y acompañar ideas y proyectos que beneficien a la comunidad en el diseño, ejecución y evaluación de los mismos.</li>
                                                        <li>Promover alianzas entre el sector público, privado y tercer sector que beneficien a la comunidad.</li>
                                                        <li>Identificar y llevar a cabo un mapeo de oportunidades con entidades de cooperación a nivel nacional e internacional que apoyen el desarrollo y mejoren la calidad de vida de la comunidad.</li>
                                                        <li>Diseñar campañas de recaudo y responsabilidad social corporativa – RSE.</li>
                                                        <li>Fomentar actividades socioculturales que promuevan los derechos de las poblaciones.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">¿Qué soñamos?</h4>
                                                    <p>
                                                        Ganemos Más, es una apuesta por construir un sueño colectivo, desde los sueños 
                                                        individuales con la premisa <strong>“hagamos que las cosas pasen”.</strong> 
                                                    </p>
                                                    <p>
                                                        Este proyecto surge como iniciativa de generar un proceso de articulación de saberes y acciones para 
                                                        fortalecer el crecimiento de las poblaciones y los actores sociales que tienen influencia en un territorio.
                                                    </p>
                                                    <p>
                                                        Para nosotros es fundamental trabajar de manera horizontal, desde un ejercicio donde no hay jerarquías, 
                                                        sino diferentes oportunidades y enfoques para el diseño, desarrollo e implementación de proyectos sociales 
                                                        y culturales, desde una lectura del contexto y, de la experiencia que puede aportar cada integrante.   
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative d-flex justify-content-center">
                                                    <img className="image-100-75" src="/assets/images/about/que-sonamos.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}




                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                {/* Start Single Slide */}
                {SlideList.map((value , index) => (
                    <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                    </div>
                                </div>
                                <div className="col-lg-4"> 
                                    <div className="video-inner">
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='gQBJfYlDOvA' onClose={() => this.setState({isOpen: false})} />
                                        <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="video-background d-flex justify-content-center align-items-center">
                            <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`}  src={`${"/assets/images/service/video.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                        </div>
                    </div>
                ))}
                {/* End Single Slide */}

                
                {/* Start Video Area  */}
                <div className="rn-section ptb--120 bg_color--1">
                    <div className="container">
                            <div className="row sercice-details-content align-items-center justify-content-center">
                            <div className="col-lg-4 p-3">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/about/ODS 4.jpg" alt="Service Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen1} videoId='Axrfh2cae7E' onClose={() => this.setState({isOpen1: false})} />
                                    <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal1}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/about/ODS 5.jpg" alt="Service Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen2} videoId='FUbA0VTykRk' onClose={() => this.setState({isOpen2: false})} />
                                    <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal2}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/about/ODS 8.jpg" alt="Service Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen3} videoId='UnVQRrxwkaQ' onClose={() => this.setState({isOpen3: false})} />
                                    <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal3}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/about/ODS 10.jpg" alt="Service Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen4} videoId='ojI69FKxqdE' onClose={() => this.setState({isOpen4: false})} />
                                    <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal4}><span className="play-icon"></span></button>
                                </div>
                            </div>
                            <div className="col-lg-4 p-3">
                                <div className="thumb position-relative">
                                    <img className="w-100" src="/assets/images/about/ODS 17.jpg" alt="Service Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen5} videoId='7aWdmDxl75k' onClose={() => this.setState({isOpen5: false})} />
                                    <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal5}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Video Area  */}

                {/* Start Team Area  */}
                {/* <div className="rn-team-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Equipo de Trabajo</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    <div>
                                        <a href="https://mpago.li/2s7pV8Y" target="_blank" className="btn btn-secondary btn-lg active" role="button" aria-pressed="true">Dona 5.000</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content d-flex flex-column justify-content-end">
                                        <h4 className="title">Debora Buendía</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                           
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content d-flex flex-column justify-content-end">
                                        <h4 className="title">BM. Alamin</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                           
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content d-flex flex-column justify-content-end">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
                {/* End Team Area  */}
                    
                </div>
                {/* End Slider Area   */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About