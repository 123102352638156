import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Brand from "../elements/Brand";
import TeamOne from "../blocks/team/TeamOne";
import Helmet from "../component/common/Helmet";
import { Link } from 'react-router-dom';

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'Ganemos Más',
        description: 'Gestionamos Alianzas y Nuevas Estrategias para Multiplicar Oportunidades Socioculturales. <br /> Mentes Actuando en Sinergia.',
        buttonText: 'Contáctenos',
        buttonLink: '/contact'
    }
]
class Home extends Component{
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div>              
                    <div className="slider-wrapper h-100"  >
                        {/* Start Single Slide */}
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24 h-100" key={index} data-black-overlay="6">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">
                                                    <span style={{color: '#f4b91b'}}>G</span>estionamos 
                                                    <span style={{color: '#f4b91b'}}> A</span>lianzas y 
                                                    <span style={{color: '#f4b91b'}}> N</span>uevas 
                                                    <span style={{color: '#f4b91b'}}> E</span>strategias para 
                                                    <span style={{color: '#f4b91b'}}> M</span>ultiplicar 
                                                    <span style={{color: '#f4b91b'}}> O</span>portunidades 
                                                    <span style={{color: '#f4b91b'}}> S</span>ocioculturales. <br />
                                                    <span style={{color: '#f4b91b'}}> M</span>entes 
                                                    <span style={{color: '#f4b91b'}}> A</span>ctuando en 
                                                    <span style={{color: '#f4b91b'}}> S</span>inergia.</p> : ''}
                                                <Link to={value.buttonLink}>
                                                    {value.buttonText ? <button type="button" className="slide-btn rn-button-style--2 btn-primary-color">{value.buttonText}</button> : ''}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Nuestros Valores</h2>
                                        <p>
                                            Desde nuestros valores hacemos posible esta construcción 
                                            <br/> y reconstrucción del tejido social:
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <TeamOne column="col-lg-4" teamStyle="" item="6" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Our Clients</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Conócenos</h2>
                                            <p className="description">En nuestra organización formulamos, diseñamos, ejecutamos y evaluamos proyectos artísticos, culturales y sociales que generan impacto y contribuyen con el mejoramiento de la calidad de vida de la comunidad, los individuos, sus familias y su entorno.</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div>
                                            <Link to="/about">
                                                <button type="button" className="rn-button-style--2 btn-primary-color mt-4 backto-top">Conoce Más</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Home;