import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    { 
                        this.props.pageTitle == "Home" 
                        ? <title>Ganemos Más</title> 
                        : <title>{this.props.pageTitle} </title> 
                    }
                    
                    <meta name="description" content="Ganemos Más es un colectivo sociocultural, constituido por gestores culturales, artistas, docentes y profesionales de diferentes áreas que suman sus saberes para contribuir a la realización de proyectos sociales desde un enfoque artístico y cultural." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
